<template>
  <div class="container mt-4 mb-4">
    <div class="row m-0 p-0 mb-4">
      <div
        class="col-md-3 col-6 p-3 shadow d-flex justify-content-center align-items-center flex-column"
        style="height: 150px"
      >
        <h6>
          <strong>{{ $route.params.source.toUpperCase() }}</strong>
          Amount
        </h6>
        <h2 class="font-weight-bolder mb-1">
          {{ sourceWallet ? sourceWallet.wallet : 0 }}
        </h2>
      </div>
    </div>

    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" @click="$router.push('/')">Home</li>
        <li class="breadcrumb-item active">
          {{ $route.params.source }}
        </li>
      </ol>
    </nav>
    <ul class="nav nav-tabs">
      <li class="nav-item" v-for="(tab, i) in tabs" :key="i">
        <a
          class="nav-link"
          :class="{ active: selected == tab.tab }"
          @click="handleRoute(tab.tab, tab.path)"
          >{{ tab.tab }}</a
        >
      </li>
    </ul>
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["sourceWallet"]),
  },
  data() {
    return {
      tabs: [
        { path: "/available-investments", tab: "Available Investments" },
        { path: "/running-investments", tab: "Running Investments" },
        { path: "/matured-investments", tab: "Matured Investments" },
      ],
      selected: "Available Investments",
    };
  },
  methods: {
    handleRoute(tab, path) {
      this.selected = tab;
      console.log("/" + this.$route.params.source + path);
      let fullPath = "/" + this.$route.params.source + path;
      this.$router.push(fullPath);
    },
  },
  mounted() {
    let route = this.$route.path;
    route.includes("available-investments")
      ? (this.selected = "Available Investments")
      : route.includes("running-investments")
      ? (this.selected = "Running Investments")
      : route.includes("matured-investments")
      ? (this.selected = "Matured Investments")
      : "";
  },
};
</script>

<style lang="scss" scoped>
h6 {
  color: #666;
}
.breadcrumb {
  li {
    text-transform: uppercase;
    color: #007bff !important;
    cursor: pointer;
  }
  .active {
    // background: #007bff !important;
    color: #666 !important;
  }
}
.nav-tabs {
  li {
    a {
      color: #666;
      cursor: pointer;
    }
    .active {
      // background: #007bff !important;
      color: #007bff !important;
    }
  }
}
</style>
